import { Download, RemoveRedEyeOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import DocIframe from "../docIframe/DocIframe";
import styles from './SearchResult.module.css'
import Modal from '@mui/material/Modal';
import { Box } from "@mui/material";
import useWindowDimensions from "../../util/useWindowDimensions";
import { useTranslation } from "react-i18next";
const SearchResult = ({ hasMore, loadingFetchMore, filteredCount, fetchMoreData, templateList }) => {
    const [fileUrl, setFileUrl] = useState('');
    const [open, setOpen] = React.useState(false);
    const { height, width } = useWindowDimensions();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90%",
        minHeight: '70vh',
        maxHeight: (height - 100) + 'px',
        maxWidth: "1040px",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const { t, i18n } = useTranslation();

    const handleClose = () => setOpen(false);
    return (<>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <DocIframe width="100%" className={styles.iFrame} height={(height - 200) + 'px'} source={fileUrl} id='google2000'></DocIframe>
            </Box>
        </Modal>
        <InfiniteScroll
            pageStart={0}
            dataLength={templateList.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={loadingFetchMore && <h4>Loading...</h4>}
            endMessage={
                <h5 onClick={fetchMoreData} className={styles.teplateFound + ' counter ' + (templateList.length < filteredCount && styles.loadMore)}>
                    {t('found')} (<strong>{templateList && templateList.length}/{filteredCount}</strong>) {t('templates')} {templateList.length < filteredCount && t('click_load_more')}
                </h5>
            }>
            <div className="row">
                {templateList && templateList.map((templateItem, templateIndex) => {
                    return <div className="col-xs-12 col-sm-6 col-md-3 " key={templateIndex}>
                        <div className={styles.content} key={templateIndex}>
                            <div className={styles.containerItem}>
                                <div className={styles.DocIframe}>
                                    <DocIframe height={'300px'} width={'100%'} key={templateIndex} source={templateItem.url}></DocIframe>
                                </div>
                                <Link to={`/previews/${templateItem.id}`}>
                                    <h3 className={styles.titleItem}>{templateItem.name}</h3>
                                </Link>
                                <div className={styles.DownloadLink}>
                                    <RemoveRedEyeOutlined fontSize='40' onClick={() => {
                                        setFileUrl(templateItem.url);
                                        setOpen(true);
                                    }}></RemoveRedEyeOutlined>
                                    <a href={templateItem.url}>
                                        <Download fontSize='40'></Download>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </InfiniteScroll>
    </>)
}
export default SearchResult


