import React, { useState, useEffect } from "react";
import Banner from "../components/banner/Banner";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
const Contact = () => {
  const { t, i18n } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    let lang = localStorage.getItem('lang');
    i18n.changeLanguage(lang);
  }, []);
  const navigate = useNavigate();
  const goToSearch = () =>
    navigate('/', {
      state: {
        searchTerm: searchTerm,
      }
    });
  const handleSearchChange = (e) => {
    let newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
  };
  const onSearchClick = (e) => {
    goToSearch();
  }
  return <>
    <Banner searchTerm={searchTerm} handleSearchChange={handleSearchChange} onSearchClick={onSearchClick}></Banner>
    <div className="container">
      <h2>{t('contact')}</h2>
      <br />
      {parse(t('contact_detail'))}
    </div>
  </>;
};

export default Contact;