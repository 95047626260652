import React from 'react';
import { Fade, Slide } from 'react-slideshow-image';
import styles from './SlideShow.module.css'
import 'react-slideshow-image/dist/styles.css';

const SlideShow = () => {
    const images = [
        "https://skaitemplate.blob.core.windows.net/nikabuy/a372f2d9-878f-415f-937b-93c7d7d7f0ca.jpg",
        "https://skaitemplate.blob.core.windows.net/nikabuy/0739c77d-a108-405f-a83a-128f9a4249e7.jpg",
        "https://skaitemplate.blob.core.windows.net/nikabuy/6c6ca875-4e47-4d22-85ab-5ccca3a97b6a.jpg",
        "https://skaitemplate.blob.core.windows.net/nikabuy/65b46731-0a57-45c0-8fa2-119a071440f4.jpg",
        "https://skaitemplate.blob.core.windows.net/nikabuy/a9af6416-ac3d-4e27-9756-2218df12c9b6.jpg",
    ];

    return (
        <div className={styles.slideShowPad}>
            <div className={styles.slideShowBanner}>
                <Fade autoplay={true}>
                    <div className={styles.eachslideeffect}>
                        <div style={{ 'backgroundImage': `url(${images[0]})` }}>
                        </div>
                    </div>
                    <div className={styles.eachslideeffect}>
                        <div style={{ 'backgroundImage': `url(${images[1]})` }}>
                        </div>
                    </div>
                    <div className={styles.eachslideeffect}>
                        <div style={{ 'backgroundImage': `url(${images[2]})` }}>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    );
};

export default SlideShow;