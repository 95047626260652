import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Banner from "../components/banner/Banner";
import { RWebShare } from "react-web-share";
import Preview from "../components/preview/preview";
import { getTemplate } from '../api/axios';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
const Previews = () => {
  const { id } = useParams();
  const [template, setTemplate] = useState({});
  const { t, i18n } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    intLoadData();
  }, []);
  function intLoadData(){
      getTemplate(id).then((result)=>{
        setTemplate(result);
      }).catch((error)=>{
        console.log(error);
      })
  }
  const navigate = useNavigate();
  const goToSearch = () =>
    navigate('/', {
      state: {
        searchTerm: searchTerm,
      }
    });
  const handleSearchChange = (e) => {
    let newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
  };
  const onSearchClick = (e) => {
    goToSearch();
  }
  return <>
    <Banner searchTerm={searchTerm} handleSearchChange={handleSearchChange} onSearchClick={onSearchClick}></Banner>
    <Preview template={template}></Preview>
  </>;
};

export default Previews;