import axios from 'axios'

export const api = axios.create({
    baseURL: 'https://api-skaitemplate.dgc-dev.com'
})

export const getPostsPage = async (searchTerm = "", numberOfItemPerPage = 1,pageNumber = 1 , options = {}) => {
    const response = await api.post(`/api/Template/public/list`,{
      "searchTerm": searchTerm,
      "numberOfItemPerPage": numberOfItemPerPage,
      "pageNumber": pageNumber
    }, options)
    return response.data
}


export const getTemplate = async (id = "0", options = {}) => {
  const response = await api.get(`/api/Template/public/${id}`)
  return response.data
}


