import React from 'react'
import { Download, FileCopyOutlined, FileOpenRounded, Share } from "@mui/icons-material";
import { RWebShare } from "react-web-share";
import { useTranslation } from "react-i18next";
import DocIframe from '../docIframe/DocIframe';
export default function Preview({ template }) {
    const { t, i18n } = useTranslation();
    return (
        <div className="container">
            <h3 className="header">{template.name}</h3>
            <div className="row">
                <div className="col-sm-12 col-md-8 col-lg-9">
                    <div className="previewBox">
                        <img src="/template.PNG"></img>
                        <img src="/template.PNG"></img>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-3">

                    <div className="row sectionDownload">
                        <a className="roundedButton download subSection" href={template.url}>
                            <Download />{t('download')}
                        </a>
                        <h3 className="subHeader subSection">{t('source_of_file')}</h3>
                        <h2>{template.groupName}</h2>
                        {/*
                        <img className="subSection" src="/ministry-of-mptc.png" alt="Ministry of Post and Telecomuniation"></img>
                        */}
                        
                        <p className="subSection">
                            <FileCopyOutlined></FileCopyOutlined> {t('file_type')}: Docx
                        </p>
                        <p className="subSection">
                            <FileOpenRounded /> {t('file_size')}: 10.9KB
                        </p>
                        <RWebShare
                            data={{
                                text: template.name,
                                url: template.url,
                                title: template.groupName,
                            }}>
                            <a className="roundedButton share subSection">
                                <Share /> {t('share')}
                            </a></RWebShare>
                    </div>
                </div>
            </div>
        </div>
    )
}
