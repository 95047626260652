import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import styles from "../App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Facebook, Telegram, YouTube } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
const Layout = () => {
    const { i18n } = useTranslation();
    useEffect(() => {
        let lang = localStorage.getItem('lang');
        i18n.changeLanguage(lang);
    }, []);
    return (
        <div className={styles.skaitemplate}>
            <Header></Header>
            <div>
                    <Outlet />
            </div>
            <div className='socialBox'>
                    <div className='socialList'>
                        <Telegram sx={{ fontSize: 40 }}></Telegram>
                        <Facebook sx={{ fontSize: 40 }}></Facebook>
                        <YouTube sx={{ fontSize: 40 }}></YouTube>
                    </div>
                </div>
        </div>
    )
};

export default Layout;