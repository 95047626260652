import { Download } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from './Header.module.css'
const Header = () => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        let lang = localStorage.getItem('lang');
        i18n.changeLanguage(lang);
    }, []);
    const changeLanguageHandler = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang);
    }
    return (
        <div className={`${styles.headerPage}`}>
            <div className='container navigationBar'>
                <div className={styles.languatNav}>
                    <ul className={styles.leftNav}>
                        <li>
                            <div onClick={()=>{
                                changeLanguageHandler('kh');
                            }}><img src="/images/cambodia.png" className={styles.topIcon}></img>ខ្មែរ</div>
                        </li>
                        <li>
                            <div onClick={()=>{
                                 changeLanguageHandler('en');
                            }}><img src="/images/united-kingdom.png" className={styles.topIcon}></img>Engish</div>
                        </li>
                    </ul>
                    <ul className={styles.rightNav}>
                        <li>
                            <Link to="/download"><Download />{t('font')} </Link>
                        </li>
                        <li>
                            <Link to="/contact">{t('contact')}</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>);
}
export default Header