import React from "react";
import Banner from "../components/banner/Banner";
const NoPage = () => {
    return <>
    <Banner></Banner>
    <div className="container">
      <p className="pageNotFound">
        <h1>Sorry page not found.</h1>
        <p>Please try other</p>
      </p>
    </div>
  </>;
};

export default NoPage;