import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect } from "react";
import styles from './Banner.module.css'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Banner = ({ searchTerm, handleSearchChange, onSearchClick }) => {
    const { t } = useTranslation();
    const [stickyClass, setStickyClass] = useState('');

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);
        return () => window.removeEventListener('scroll', stickNavbar);
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            // window height changed for the demo
            windowHeight > 50 ? setStickyClass('sticky-nav') : setStickyClass('');
        }
    };

    return (
        <div className={`navbar ${stickyClass}`}>
            <div className={`${styles.headerPage} headerNav`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className={"col-md-3 d-none d-sm-none d-md-block " + styles.logoPan}>
                            <Link to="/">
                                <img src='/skailab-2022-2.png' className={styles.logo}></img>
                            </Link>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <Paper
                                className={styles.searchPage}
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', maxWidth: 600, alignItems: 'center', borderRadius: 20, padding: "0 0 0 20px" }}>
                                <InputBase
                                    sx={{ ml: 1, flex: 1, fontSize: 18 }}
                                    placeholder={t("search_template")}
                                    inputProps={{ 'aria-label': t("search_template")}}
                                    value={searchTerm}
                                    onChange={(handleSearchChange)}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                ></InputBase>
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={onSearchClick}>
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>  </div>)
}
export default Banner

