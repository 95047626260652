import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React from "react";
import Contact from './pages/Contact';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Download from './pages/Download';
import NoPage from './pages/NoPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Previews from './pages/Previews';
import { LanguageProvider} from './context/LanguageContext'
//import ReactGA, {} from "react-ga4";
export const LanguageContext = React.createContext();
//ReactGA.initialize("G-8KE6DJ4234");
//ReactGA.send("pageview");
function App() {
  return (
    <LanguageProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index path='/' element={<Home />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Download" element={<Download />} />
            <Route path="/Previews/:id" element={<Previews />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      
    </LanguageProvider>

  );
}

export default App;
