import React, { useState, useRef, useEffect } from 'react'
import SlideShow from "../components/slideshow/SlideShow";
import Banner from "../components/banner/Banner";
import { getPostsPage } from '../api/axios';
import 'react-slideshow-image/dist/styles.css';
import useWindowDimensions from '../util/useWindowDimensions';
import SearchResult from '../components/searchresult/SearchResult';
import { useLocation, useParams } from 'react-router-dom';
import { useLanguage, useLanguageUpdate} from '../context/LanguageContext'
import { useTranslation } from "react-i18next";

const Home = () => {

    const language = useLanguage();
    const setLanguage = useLanguageUpdate();
    const { t } = useTranslation();
    const location = useLocation();
    const searchQuery = location.state !== null ? location.state.searchTerm : "";
   
    const numberOfItemPerPage = 8;
    const [pageNumber, setPage] = useState(1);
    const [templateList, setTemplateList] = useState([]);
    const [loading, setIsLoading] = useState(false);
    const [loadingFetchMore, setIsLoadingFetchMore] = useState(false);
    const [searchTerm, setSearchTerm] = useState(searchQuery);
    const [totalPages, setTotalPages] = useState(0);
    const [filteredCount, setFilteredCount] = useState(0);
    const headerRef = useRef(null);
    const [hasMore, setHasMore] = useState(true);
    const [fileUrl, setFileUrl] = useState(true);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setSearchTerm(searchQuery);
        intLoadData(searchTerm, numberOfItemPerPage);
    }, []);
    const { height, width } = useWindowDimensions();
    const handleSearchChange = (e) => {
        let newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
    }
    const onSearchClick = (e) => {
        intLoadData(searchTerm, numberOfItemPerPage);
    }
    function intLoadData(searchTerm, numberOfItemPerPage) {
        
        let inttotal = 0;
        setTotalPages(inttotal);
        setFilteredCount(inttotal);
        setIsLoading(true);
        setTemplateList([...[]]);
        setPage(1);
        getPostsPage(searchTerm, numberOfItemPerPage, 1, {})
            .then(result => {
                setIsLoading(false);
                setTemplateList(result.data);
                setTotalPages(result.totalPages);
                setFilteredCount(result.filteredCount);
                // reset to next page 
                console.log(pageNumber, ' - ', result);
            })
            .catch(e => {
                setIsLoading(false)
            });
    }
    function getTemplateMore(searchTerm, numberOfItemPerPage, pageNumber) {
        setIsLoadingFetchMore(true);
        getPostsPage(searchTerm, numberOfItemPerPage, pageNumber, {})
            .then(result => {
                setTemplateList([...templateList, ...result.data]);
                setPage(pageNumber);
                setIsLoadingFetchMore(false);
            })
            .catch(e => {
                setIsLoadingFetchMore(false);
            });
    }
    const fetchMoreData = () => {
        console.log('get last');
        if (pageNumber >= totalPages) {
            console.log('last page');
            console.log(pageNumber, ' - ', totalPages);
            setHasMore(false);
            return;
        } else {
            getTemplateMore(searchTerm, numberOfItemPerPage, pageNumber + 1);
        }

    };
    return <>
        <Banner searchTerm={searchTerm} handleSearchChange={handleSearchChange} onSearchClick={onSearchClick}></Banner>
        <div className="container">
            <SlideShow></SlideShow>
        </div>
        <div className='container'>
            <SearchResult hasMore={hasMore} loadingFetchMore={loadingFetchMore} filteredCount={filteredCount} fetchMoreData={fetchMoreData} templateList={templateList}/>
        </div>
    </>;
};

export default Home;