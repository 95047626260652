import React from "react";
import Iframe from 'react-iframe'
const DocIframe = ({ source, width, height }) => {
  if (!source) {
    return <div>Loading...</div>;
  }

  const src = source;
  const widthFrame = width;
  const heightFrame = height;
  return (
    <div>
      <Iframe
        src={"https://view.officeapps.live.com/op/embed.aspx?src=" + src + ""}
        title="file"
        width={widthFrame}
        height={heightFrame}
        frameborder="0"
      ></Iframe>
    </div>
  );
};

export default DocIframe;