import React, { useContext, useState } from 'react'
const LanguageContext = React.createContext();
const LanguageUpdateContext = React.createContext();

export function useLanguage() {
    return useContext(LanguageContext);
}

export function useLanguageUpdate() {
    return useContext(LanguageUpdateContext);
}

export function LanguageProvider({ children }) {
    const [language, setLang] = useState('en');
    function setLanguage(lang) {
        setLang(lang);
    }
    return (
        <LanguageContext.Provider value={language}>
            <LanguageUpdateContext.Provider value={setLanguage}>
                {children}
            </LanguageUpdateContext.Provider>
        </LanguageContext.Provider>
    );
}