import React, { useState, useEffect } from "react";
import SlideShow from "../components/slideshow/SlideShow";
import Banner from "../components/banner/Banner";
import { Link, useNavigate } from "react-router-dom";
import { DownloadDone, FileDownload } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { display } from "@mui/system";
const Download = () => {
  const { t, i18n } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    let lang = localStorage.getItem('lang');
    i18n.changeLanguage(lang);
  }, []);
  const goToSearch = () =>
    navigate('/', {
      state: {
        searchTerm: searchTerm,
      }
    });
  const handleSearchChange = (e) => {
    let newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
  };
  const onSearchClick = (e) => {
    goToSearch();
  }
  const fonts = [{
    fontName: "Moul",
    fontIamge: "https://www.fontsc.com/font/preview/moul",
    fontOwner: "Danh Hong",
    url: "https://www.fontsc.com/font/download/moul"
  },
  {
    fontName: "Koulen",
    fontIamge: "https://www.fontsc.com/font/preview/koulen",
    fontOwner: "Danh Hong",
    url: "https://www.fontsc.com/font/download/koulen"
  },
  {
    fontName: "Taprom",
    fontIamge: "https://www.fontsc.com/font/preview/taprom",
    fontOwner: "Danh Hong",
    url: "https://www.fontsc.com/font/download/taprom"
  },
  {
    fontName: "Moulpali",
    fontIamge: "https://www.fontsc.com//font/preview/moulpali",
    fontOwner: "Danh Hong",
    url: "https://www.fontsc.com/font/download/moulpali"
  },
  {
    fontName: "Angkor",
    fontIamge: "https://www.fontsc.com/font/preview/bayon",
    fontOwner: "Danh Hong",
    url: "https://www.fontsc.com/font/download/angkor"
  }];
  return <>
    <Banner SearchTerm={searchTerm} handleSearchChange={handleSearchChange} onSearchClick={onSearchClick}></Banner>
    <div className="container">
      <h2>{t('font')}</h2>
      <div className="downloadPage">
        <div className="downloadlink">
          <p>{t("download")} </p><a href="./fonts/KhmerFonts_forMac.zip" title="download">All Khmer Unicode Fonts.zip</a>
        </div>
        <div className="downloadlink">
          <p>{t("download")} </p><a href="./fonts/KhmerOSfonts.zip" title="download">MPTC Font.zip</a>
        </div>
        <div>
          <h4>{t("how_to_install")}</h4>
          <p>{t("how_to_install_desc")}</p>
          <img className="howToInstall" src="./how-to-install-khmer-font.jpg" title="how to install font"></img>
        </div>
      </div>

      <div style={{ display: 'none' }}>
        <ul className="fontList">
          {
            fonts.map((font, index) => {
              return (<li key={index}>
                <div className="row align-items-center">
                  <div className="col-sx-12 col-sm-8 col-md-11">

                    <div>
                      <img src={
                        font.fontIamge
                      }></img>
                    </div>
                    <div className="owner">
                      <p class="fontName">
                        {
                          font.fontName
                        } by {
                          font.fontOwner
                        }
                      </p>
                    </div>

                  </div>
                  <div className="col-sx-12 col-sm-4 col-md-1">
                    <a className="fontLink" href={font.url} title={font.fontName + " by " + font.fontOwner}>
                      <FileDownload />
                    </a>
                  </div>
                </div>
              </li>)
            })
          }

        </ul>
      </div>
    </div>
  </>;
};

export default Download;